import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { components, ReactDOM, useEffect } from 'react';
import Chart from "chart.js/auto";

import Navbar from './componentes/Navbar';
import Footer from './componentes/Footer/Footer';

import Home from './pages/Homepage';
import About from './pages/Aboutpage';
import One from './pages/Onepage';
import Two from './pages/Twopage';
import Three from './pages/Threepage';
import Graph from './pages/graphpage';

import NoPage from './pages/NoPages';

import ReactGA from 'react-ga4' ; ReactGA.initialize('Your-Measurement-ID') ;

function App() {
  useEffect(() => { ReactGA.send({ hitType : "pageview", page : window.location.pathname }) ; }, []) ;
  const handleButtonClick = () => { ReactGA.event({ category : 'User', action : 'Clicked a button' }) ; } ;
  return (
  <div className="App">
    <head>
      {/* <!-- Google Tag Manager --> */}
      {/* <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-NKWGFJ9J');</script> */}
      {/* <!-- End Google Tag Manager --> */}
    </head>


      
    <Router>
      <Navbar />
      <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route path='/About' element={<About/>} />
          <Route path='/PageOne' element={<One/>} />
          <Route path='/Pagetwo' element={<Two/>} />
          <Route path='/Pagethree' element={<Three/>} />
          <Route path='/graph' element={<Graph/>} />
          {/* <Route path='/Cat' element={<FourthPage/>} />
          <Route path='/test' element={<FivePage/>} /> */}
          <Route path='*' element={<NoPage/>} />
      </Routes>
      <Footer />
    </Router>
    
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NKWGFJ9J"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
  </div>
    
  );
}

export default App;
