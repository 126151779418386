import React from 'react'

import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Line} from "react-chartjs-2";
import '../App.css'
// import '../api.js'

import axios from 'axios';
import { useState, useEffect } from 'react'  

const GraphPage =() =>{
    const [Weighpoints, setWeighpoints] = useState([])


    useEffect(() => {
    //    axios.get('/ktct/v1/KitCatDat/')
        axios.get('https://m2c1.freeboxos.fr/ktct/v1/KitCatDat/')
        // api.get('/')
        .then(response => {
            setWeighpoints(response.data.result)
        })
        .catch(error => {
          console.error(error)
        })
    }, [])

    const DataRow = ({ data }) => {
        return (
          <Row className='wgtWd'>
            {data.map((item) => (
              <Col key={item.id}>
                <section>
                    <h6>{item.DateTime}</h6>
                    <h4>  {(Math.round(item.WgtCat /10)/100)} Kg</h4>
                    <h4>  {(Math.round(item.LgtFoo*100)/100)} g</h4>
                    { (item.UtiDur) > 60
                    ? <h6> en {(Math.round((item.UtiDur/60)*100)/100)} minutes</h6>
                    : <h6> en {item.UtiDur} sec</h6>
                }
                </section>
              </Col>
            ))}
          </Row>
        );
      };

    return (

    <div className='Graph' >

        <Container>
        <Row>
            <Col>
            <Container>
            <div>
                    {/* Dernier data */}
                    <DataRow data={Weighpoints.slice(-1)} />
            </div>
            </Container>
            <Accordion>
                <Accordion.Item>
                    <Accordion.Body>
                    
                        <Container>
                            <div>
                                    {/* plus anciennes data */}
                                    <DataRow data={Weighpoints.slice(0,5)} />
                            </div>
                        </Container>
                        <Container>
                            <div>
                                    {/* intermediaire */}
                                    <DataRow data={Weighpoints.slice(5, 10)} />
                            </div>    
                        </Container>
                        <Container>
                            <div>
                                    {/* Plus recentes data */}
                                    <DataRow data={Weighpoints.slice(10, 15)} />
                            </div>    
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            </Col>
            
                <Col>
                    <div>
                        <Line data={{
                        labels: Weighpoints.map((data) => data.DateTime),
                            datasets: [
                                {
                                    label: 'Chat',
                                    data: Weighpoints.map((data) => ((data.WgtCat)/1000)) ,
                                    // borderColor: "red",
                                    borderColor:'grey',
                                    backgroundColor: 'grey',
                                
                                }]
                        }} />
                    </div>
 
                    <div>
                        <Line data={{
                        labels: Weighpoints.map((data) => data.DateTime),
                            datasets: [
                                {
                                    label: 'Croquettes',
                                    data: Weighpoints.map((data) => data.LgtFoo),
                                    // borderColor: "red",
                                    borderColor:'red',
                                    backgroundColor: 'red',
                                    
                                }]
                        }} />
                    </div>

                </Col>
            </Row>
        </Container>

    </div>
)}
export default GraphPage;